import * as React from "react"
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core"
import Register from "./Register"

const styles = (theme: Theme) =>
  createStyles({
    root: {
      background: theme.background,
      border: 0,
      fontSize: 16,
      borderRadius: 3,
      boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
      color: "white",
      height: 48,
      padding: "0 30px"
    }
  })

interface PageProps extends WithStyles<typeof styles> {
  className?: string
}

const Page = (props: PageProps) => (
  <div>
    {" "}
    <h1>Welcome</h1>
    <p>The place to discover the adventures of programming the ESP32</p>
    <p>Coming Soon...</p>
    <Register />
    <button type="button" className={props.classes.root}>
      Theming
    </button>
  </div>
)

export default withStyles(styles)(Page)
